import React, { Component } from "react";
import logo from "./dappnode-logo.png";

class App extends Component {
  render() {
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col header text-center mt-3">DAppNode newsletter</div>
        </div>

        <div className="row mb-3">
          <div className="col img-canvas text-center">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="row mt-3 mb-4">
          <div
            className="col text-center mx-auto"
            style={{ maxWidth: "600px", opacity: 0.8 }}
          >
            <p>
              We are very close to open our shop where you can buy a physical
              server with DAppNode pre-installed. We will always encourage
              everyone to install or build it on your own, but we provide this
              option if you want to save time and support our team.
            </p>
          </div>
        </div>

        <div className="container" style={{ maxWidth: "600px" }}>
          <div className="row text-center mx-auto">
            <div id="mc_embed_signup" style={{ margin: "auto" }}>
              <form
                action="https://dappnode.us20.list-manage.com/subscribe/post?u=3d6ca1ff3a1c8a23e11f899d8&amp;id=d2005b671e"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <label htmlFor="mce-EMAIL" style={{ fontSize: "120%" }}>
                    Be the first to get a dappnode, subscribe to our newsletter
                  </label>

                  <div className="input-group mb-3">
                    <input
                      type="email"
                      defaultValue=""
                      name="EMAIL"
                      className="email form-control"
                      id="mce-EMAIL"
                      placeholder="email address"
                      required
                    />

                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_3d6ca1ff3a1c8a23e11f899d8_d2005b671e"
                        tabIndex="-1"
                        defaultValue=""
                      />
                    </div>
                    <div className="input-group-append clear">
                      <input
                        readOnly
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="btn btn-secondary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
